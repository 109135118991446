import {decompress} from "lzutf8";

/**
 * Checks if there is a template asset prefix override and applies it.
 *
 * @param path String A path to file
 * @return String
 */
export function prepareAssetPath(path) {
  const isAbsolute = path.indexOf('http://') === 0 || path.indexOf('https://') === 0;

  if (isAbsolute) {
    return path;
  }

  return window.TEMPLATE_ASSET_PREFIX ? `${window.TEMPLATE_ASSET_PREFIX}${path}` : path;
}

export function extractData() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params.data) {
    try {
      const decoded = decodeURIComponent(params.data);
      const decompressed = decompress(decoded, {
        inputEncoding: 'Base64',
      });

      return JSON.parse(decompressed);
    } catch (err) {
      try {
        return JSON.parse(params.data);
      } catch (errr) {
        console.error('could not parse data string.', err, errr);
      }
    }
  }
}

export const containsKorean = (text) => {
  try {
    const re = /[\u3131-\uD79D]/ugi
    return text?.match(re)?.length > 0;
  } catch (e) {
    return false;
  }
}

export const containsChinese = (text) => {
  try {
    const re = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/ugi
    return text?.match(re)?.length > 0;
  } catch (e) {
    return false;
  }
}

export const containsJapanese = (text) => {
  try {
    const re = /[\u3402-\uFA6D\u3041-\u30A0\u30A0-\u31FF]/ugi
    return text?.match(re)?.length > 0;
  } catch (e) {
    return false
  }
}

export const containsLanguage = (content, deep = true) => {
  const c = deep ? JSON.stringify(content) : content;

  if (containsChinese(c)) {
    return 'chinese';
  }

  if (containsKorean(c)) {
    return 'korean';
  }

  return 'english'
};

/**
 * Lets the PixelMap know the source template is ready.
 * Will call the callback whenever it needs to restart playing.
 *
 * @param shouldPlayCallback Triggered by the PixelMap whenever ready.
 */
export const pixelMapReady = (shouldPlayCallback) => {
  const event = new CustomEvent('animation_ready');
  window.dispatchEvent(event);

  window.addEventListener('restart_animation', shouldPlayCallback, false);
}

export const dispatchRestart = () => {
  const event = new CustomEvent('restart_animation');
  window.dispatchEvent(event);
}

export const storage = {
  init: (name) => {
    set(name);
  },
  get: (name, defaultValue) => {
    const value = localStorage.getItem(name);
    return value ? JSON.parse(value) : defaultValue;
  },
  set: (name, value = null) => {
    localStorage.setItem(name, JSON.stringify(value));
  },
};

export const forceHttps = (url) => {
  try {
    return url.replace('http://', 'https://')
  } catch (e) {
    return url;
  }
}
